/* eslint-disable jsx-a11y/img-redundant-alt */
import { useTranslation } from "react-i18next";
import "./style/Aboutus.css";
import image1 from "./image/gife/gife.png";
import image2 from "./image/gife/photo2.jpg";
import image3 from "./image/gife/iphone.png";
import image4 from "./image/gife/photo4.jpg";
import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll"; // Import the scroll function
import { Button } from "antd";
import { useEffect, useState } from "react";
const Register = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };
  const [showScrollButton, setShowScrollButton] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <div>
        <div className="Text-Top">
          <h1 className="Text-About">{t("GifeA")}</h1>
          <div>
            <img src={image1} alt="image" className="image1" />
          </div>
          <h1 className="Text-About">{t("Gifeb")}</h1>
          <div>
            <img src={image2} alt="image" className="image1" />
          </div>
          <h1 className="Text-About">{t("Gifec")}</h1>
          <div>
            <img src={image4} alt="image" className="image" />
          </div>
          <h1 className="Text-About">{t("Gifed")}</h1>
          <div>
            <img src={image3} alt="image" className="image" />
          </div>
        </div>
      </div>
      {showScrollButton && (
        <Button
          type="primary"
          className="scroll-to-top-button"
          onClick={scrollToTop} // Add onClick handler for scrolling to top
        >
          <ArrowUpOutlined />
        </Button>
      )}
    </div>
  );
};

export default Register;
