import "./style/Network.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { List, Spin } from "antd";
import { useTranslation } from "react-i18next";
const Network = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    axios
      .get(`https://bardawamplus.com/api/users/${id}`)
      .then((response) => {
        setOrderDetails(response.data);
        setLoading(false);
        // console.log("Order details fetched successfully:", response.data);
      })
      .catch((error) => {
        console.error("Error fetching order details:", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <Spin size="large" />;
  }

  if (!orderDetails) {
    return <p>Order details not found.</p>;
  }

  return (
    <div>
      <div className="order-details-container">
        <h1 className="NewUserHeading">{t("person")}</h1>
        {/* <div className="order-details-content"></div> */}
        <List>
          <hr className="divider" />
          {orderDetails.referrals.map((product, index) => (
            <List.Item key={index} className="product-item">
              <div className="product-info">
                <p> {product.name} </p>
              </div>
            </List.Item>
          ))}
        </List>
      </div>
    </div>
  );
};

export default Network;
