import React, { useState } from "react";
import { Button, Form, Input, Typography, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const { Text, Title } = Typography;

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const PostRegister = () => {
    const URI = "https://bardawamplus.com/api/login";

    const requestBody = {
      email,
      password,
    };

    fetch(URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(async (data) => {
        Cookies.set("login", true);
        Cookies.set("user", JSON.stringify(data.user));

        window.location.href = "/";
      })
      .catch((error) => {
        message.error(t("F"));
        // Handle error message here
      });
  };

  const onFinish = (values) => {
    // console.log("Received values of form: ", values);
  };

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 20,
    },
  };
  return (
    <div
      style={{
        maxWidth: 800,
        margin: "auto",
        marginTop: 50,
        marginBottom: 100,
        padding: 20,
        border: "1px solid #ddd",
        borderRadius: 8,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        direction: "rtl",
      }}
    >
      <section>
        <div className="container">
          <div className="header">
            <Title
              style={{
                fontSize: 25,
              }}
            >
              {t("Sign in")}
            </Title>
            <Text style={{ fontSize: "20px", marginBottom: 30 }}>
              {t("Welcome")}
            </Text>
          </div>
          <br />
          <Form
            {...layout}
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: t("Type your Email"),
                },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder={t("Email")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("Please input your password!"),
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                type="password"
                placeholder={t("Password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: "0px", textAlign: "center", fontSize: 25 }}
            >
              <Button type="primary" htmlType="submit" onClick={PostRegister}>
                {t("Sign in")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section>
    </div>
  );
};
export default Login;
