/* eslint-disable jsx-a11y/img-redundant-alt */
import "./style/Profile.css";
import Profilee from "./image/user.png";
import invite from "./image/referral.png";
import point from "./image/reward-card.png";
import wallet from "./image/wallet (1).png";
import Levels from "./image/success.png";
import WhatsApp from "./image/whatsapp (1).png";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Button, Modal, Input } from "antd";
import Cookies from "js-cookie";
import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll"; // Import the scroll function
import { Link } from "react-router-dom";
const Profile = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [GetDate, setGetdata] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedUserData, setUpdatedUserData] = useState({
    name: "",
    email: "",
    number: "",
    address: "",
  });
  //todo -----------------------------
  const [level, setLevel] = useState("");

  useEffect(() => {
    const fetchData = () => {
      const userData = Cookies.get("user");
      setUser(JSON.parse(userData));
      const userId = JSON.parse(userData)?.id;

      if (userId) {
        fetch(`https://bardawamplus.com/api/users/${userId}`)
          .then((response) => response.json())
          .then((data) => {
            setGetdata(data);
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          });
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (GetDate.points > 0 && GetDate.points <= 100) {
      setLevel(t("Levels1"));
    } else if (GetDate.points > 100 && GetDate.points <= 500) {
      setLevel(t("Levels2"));
    } else if (GetDate.points > 500 && GetDate.points <= 2500) {
      setLevel(t("Levels3"));
    } else if (GetDate.points > 2500 && GetDate.points <= 5000) {
      setLevel(t("Levels4"));
    } else if (GetDate.points > 5000 && GetDate.points <= 10000) {
      setLevel(t("Levels5"));
    } else if (GetDate.points > 10000 && GetDate.points <= 100000) {
      setLevel(t("Levels6"));
    } else if (GetDate.points > 100000) {
      setLevel(t("Levels7"));
    } else {
      setLevel(t(""));
    }
  }, [GetDate.points, t]);

  // useEffect(() => {
  //   if (level) {
  //   }
  // }, [level]);
  //todo -----------------------------

  // const [showcode , setShowcode]=useState({})
  useEffect(() => {
    const fetchData = () => {
      // Fetch user data from cookies
      const userData = Cookies.get("user");
      setUser(JSON.parse(userData));

      // Fetch user data from the API using the user ID
      const userId = JSON.parse(userData)?.id;

      if (userId) {
        fetch(`https://bardawamplus.com/api/users/${userId}`)
          .then((response) => response.json())
          .then((data) => {
            setGetdata(data);
            // console.log(data);
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          });
      }
    };

    fetchData();
  }, []);
  //todo --------------------------------

  //todo --------------------------------
  useEffect(() => {
    if (isModalOpen) {
      // Fetch user data when the modal is opened
      const userId = user?.id;

      if (userId) {
        fetch(`https://bardawamplus.com/api/users/${userId}`)
          .then((response) => response.json())

          .then((data) => {
            setUpdatedUserData({
              name: data.name ?? "",
              email: data.email ?? "",
              number: data.number ?? "",
              address: data.address ?? "",
            });
            // console.log(data);
          })

          .catch((error) => {
            console.error("Error fetching user data:", error);
          });
      }
    }
  }, [isModalOpen, user]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (fieldName, value) => {
    setUpdatedUserData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleUpdateProfile = () => {
    const userId = user?.id;

    if (userId) {
      fetch(`https://bardawamplus.com/api/users/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedUserData),
      })
        .then((response) => {
          if (response.ok) {
            //todo User data updated successfully

            const olduser = Cookies.get("user");
            const newuser = JSON.stringify(updatedUserData);

            // Parse JSON strings to convert them to objects
            const parsedOldUser = JSON.parse(olduser || "{}");
            const parsedNewUser = JSON.parse(newuser);

            // Merge old and new user objects using the spread operator
            const mergedUser = { ...parsedOldUser, ...parsedNewUser };

            // Convert the merged user object back to a JSON string
            const mergedUserJson = JSON.stringify(mergedUser);

            // Update the user cookie with the merged user object
            Cookies.set("user", mergedUserJson);
            setUser(mergedUser);

            // console.log(response.data);
            handleCloseModal(); // Close the modal after successful update
          } else {
            console.error("Failed to update user data");
          }
        })
        .catch((error) => {
          console.error("Error updating user data:", error);
        });
    }
  };
  //todo --------------------------------
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };
  return (
    <div>
      <div className="grid-container">
        <div className="grid-cell">
          <ul className="item-UI">
            <li className="item-icon">
              <img src={Profilee} alt=" " width={120} height={120} />
            </li>
            <li className="item-title">{t("Profile")}</li>
            <li className="item-paragraph"> {user?.name ?? ""}</li>
            <li className="item-paragraph"> {user?.email ?? ""}</li>
            <li className="item-paragraph"> {user?.number ?? ""}</li>
            <li className="item-paragraph"> {user?.address ?? ""}</li>
            <li className="item-paragraph">{user?.referential_code ?? ""}</li>
            <Button
              style={{
                fontSize: 14,
                color: "white", // Add this line to change the color to white
                background: "green",
                border: "2px solid #ccc",
              }}
              onClick={handleOpenModal}
            >
              {t("Update")}
            </Button>
          </ul>
        </div>
        <div className="grid-cell">
          <ul className="item-UI">
            <li className="item-icon">
              <img src={invite} alt=" " width={120} height={120} />
            </li>
            <li className="item-title">{t("invite")}</li>
            <li className="item-paragraph">
              {GetDate && GetDate.referrals && GetDate.referrals.length > 0 ? (
                <Link to={`/Profile/Network/${user?.id}`}>
                  {GetDate.referrals.length}
                </Link>
              ) : (
                <p>{t("NoOne")}</p>
              )}
            </li>
          </ul>
        </div>
        <div className="grid-cell">
          <ul className="item-UI">
            <li className="item-icon">
              <img src={point} alt=" " width={120} height={120} />
            </li>
            <li className="item-title">{t("point")}</li>
            <li className="item-paragraph">
              {GetDate ? GetDate.points : null}
            </li>
            <br />
            <br />
          </ul>
        </div>
        <div className="grid-cell">
          <ul className="item-UI">
            <li className="item-icon">
              <img src={Levels} alt=" " width={120} height={120} />
            </li>
            <li className="item-title">{t("Astakan")}</li>
            <li className="item-paragraph">{level}</li>
            <br />
            <br />
          </ul>
        </div>
        <div className="grid-cell">
          <ul className="item-UI">
            <li className="item-icon">
              <img src={wallet} alt=" " width={120} height={120} />
            </li>
            <li className="item-title">{t("wallet")}</li>
            <li className="item-paragraph">
              {GetDate ? GetDate.balance : null}
            </li>
          </ul>
        </div>

        {/* //todo -------modle */}
        <Modal
          open={isModalOpen} // Change 'visible' to 'open'
          style={{
            direction: "rtl", // Set direction to right-to-left
          }}
          onCancel={handleCloseModal}
          footer={null} // Setting footer to null hides both the cancel and OK buttons
        >
          <div className="modal-container">
            <h2 style={{ fontSize: 24 }}>{t("Update User Information")}</h2>
            {/* <Input
              style={{ fontSize: 16 }}
              placeholder={t("Name")}
              value={updatedUserData.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            <Input
              style={{ fontSize: 16 }}
              placeholder={t("Email")}
              value={updatedUserData.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
            /> */}
            <Input
              style={{ fontSize: 16 }}
              placeholder={t("Number")}
              value={updatedUserData.number}
              onChange={(e) => handleInputChange("number", e.target.value)}
            />
            <Input
              style={{ fontSize: 16 }}
              placeholder={t("Address")}
              value={updatedUserData.address}
              onChange={(e) => handleInputChange("address", e.target.value)}
            />
            <Button style={{ fontSize: 16 }} onClick={handleUpdateProfile}>
              {t("save change")}
            </Button>
          </div>
        </Modal>
        {/* //todo -------modle */}
      </div>
      <div className="Text-Top">
        <h1 className="Text-About">
          {t("TB")}
          <p>
            <a href="https://wa.link/qhntsc" target="_blank" rel="noreferrer">
              <img src={WhatsApp} alt="image" width={50} height={50} />
            </a>
          </p>
        </h1>
      </div>
      <Button
        type="primary"
        className="scroll-to-top-button"
        onClick={scrollToTop} // Add onClick handler for scrolling to top
      >
        <ArrowUpOutlined />
      </Button>
    </div>
  );
};

export default Profile;
