import { Link, Outlet } from "react-router-dom";
import { ShoppingCartOutlined } from "@ant-design/icons";

// import "../page/style/NavbarShping.css";
import "../Navbarshoping/style/NavbarShping.css";
//todo krdari Rawter ler rw dadat
// eslint-disable-next-line react/prop-types
import { useTranslation } from "react-i18next";

const Home = ({ size, setShow }) => {
  const { t } = useTranslation();
  return (
    <div>
      <nav>
        <div className="nav_box">
          <span className="my_shop" onClick={() => setShow(true)}>
            <Link className="my_shop" to="/">
              {t("My Shopping")}
            </Link>
          </span>
          <div className="cart" onClick={() => setShow(false)}>
            <span>
              <Link className="cart" to="/Cart">
                {/* <i className="fas fa-cart-plus"></i> */}
                <ShoppingCartOutlined
                  style={{ color: "black", fontSize: 33 }}
                />
              </Link>
            </span>
            <span>{size}</span>
          </div>
        </div>
        <Outlet />
      </nav>
    </div>
    //todo ----------------------------------------
  );
};

export default Home;
