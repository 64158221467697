import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// -----------------------------------------------------
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import LOGO from "./image/LogoBradwam.png";
import Kurdish from "./image/kurdstan.png";
import iraq from "./image/iraq.png";
import lang from "./image/languages.png";
import loginn from "./image/user.png";
import MenuBar from "./image/icons8-menu-bar-50.png";
import "./NavBar.css";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";

const NavBar = () => {
  // todo------------------------------
  const [language, setLanguage] = useState("");
  const { t } = useTranslation();
  const onChangeLang = (e) => {
    const selectedLang = e.target.value;
    setLanguage(selectedLang);
    i18n.changeLanguage(selectedLang);
    Cookies.set("language", selectedLang); // Save selected language to a cookie
    localStorage.removeItem("i18nextLng");
    // window.location.reload();
  };

  useEffect(() => {
    const savedLang = Cookies.get("language");
    if (savedLang) {
      setLanguage(savedLang);
      i18n.changeLanguage(savedLang);
    }
  }, []);

  const [login, setLogin] = useState(true);

  const seelogin = async () => {
    const loginCookieValue = await Cookies.get("login");

    if (loginCookieValue === "true") {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  useEffect(() => {
    seelogin();
  }, []);
  // todo------------------------------
  return (
    <div>
      <nav className="  navbar navbar-expand-lg navbar-light bg-light nav ">
        <div className="container py-2">
          {/* <Link className="navbar-brand " to="logo">
            logo
            </Link> */}
          <img src={LOGO} height={100} width={100} alt=" " />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src={MenuBar} height={25} width={25} alt="menu" />
            {/* <span className="navbar-toggler-icon"></span> */}
          </button>
          {/* mean */}
          <div className="collapse navbar-collapse align-middle" id="navbarNav">
            <ul className="navbar-nav ms-auto nav_ul align-items-center navbar">
              <li className="nav-item ">
                <Link className="nav-link link" to="/">
                  {t("home")}
                </Link>
              </li>
              {login ? (
                <li className="nav-item ">
                  <Link className="nav-link link" to="/Profile">
                    {t("Profile")}
                  </Link>
                </li>
              ) : (
                <>
                  {/* <li className="nav-item ">
                    <Link className="nav-link link" to="/User">
                      User Profile
                    </Link>
                  </li> */}
                </>
              )}
              <li className="nav-item ">
                <Link className="nav-link link" to="/Gift">
                  {t("gife")}
                </Link>
              </li>
              <li className="nav-item ">
                <Link className="nav-link link" to="/aboutUs">
                  {t("About")}
                </Link>
              </li>

              <FormControl
                style={{
                  // color: "red",
                  margin: 1,
                  minWidth: 120,
                  borderRadius: 3,
                  backgroundColor: "rgb(4, 96, 187)",
                  padding: 0,
                }}
              >
                <InputLabel
                  id="language-select-label"
                  style={{
                    fontWeight: "bold",
                    fontSize: 16,
                    color: "white",
                  }}
                >
                  {t("lang")} <span> </span>
                  <img src={lang} height={25} width={25} alt="kurdish" />
                </InputLabel>
                <Select
                  value={language || ""}
                  onChange={onChangeLang}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  style={{
                    marginTop: 5,
                    fontSize: 19,
                    color: "white",
                  }}
                >
                  <MenuItem
                    value="ku"
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    {t("Kurdish")}
                    <span> </span>
                    <img src={Kurdish} height={25} width={25} alt="kurdish" />
                  </MenuItem>
                  <MenuItem
                    value="ar"
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    {t("Arabic")}
                    <span> </span>
                    <img src={iraq} height={25} width={25} alt="iraq" />
                  </MenuItem>
                </Select>
              </FormControl>

              {/* //todo-------------------------------------------------- */}
              {login ? null : (
                <div>
                  <Link to="/Login">
                    <button
                      type="button"
                      className="btn btn-dark mx-5 large-btn"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "90px",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "90px",
                      }}
                    >
                      {t("Sign in")}
                      <span> </span>
                      <img src={loginn} height={25} width={25} alt="login" />
                    </button>
                  </Link>
                </div>
              )}
              {/* //todo-------------------------------------------------- */}
              {/* <div>
                <Link to="/Login">
                  <button
                    type="button"
                    className="btn btn-dark  mx-5 large-btn"
                  >
                    {t("Sign in")}
                  </button>
                </Link>
              </div> */}
              {/* //todo-------------------------------------------------- */}
            </ul>
          </div>
          {/* end_navbar */}
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
