/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
import i18n from "../../i18n";
import { useState, useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import "./style/Cart.css";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";

const CartShop = ({ cart, setCart, handleChange }) => {
  const { t } = useTranslation();

  const [sub_amount, setsub_amount] = useState(0);
  const [note, setNote] = useState("");
  const [deliveryFee] = useState(5000);
  const [deliveryFeeIraq] = useState(8000);
  const [user, setUser] = useState(null);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const getTranslatedName = (nameJson) => {
    const nameTranslations = JSON.parse(nameJson);
    const currentLanguage = i18n.language || "ku";
    return nameTranslations[currentLanguage] || nameTranslations["ku"]; // Fallback to 'ku' if translation is not found
  };
  // todo -------------
  useEffect(() => {
    const userData = Cookies.get("user");
    if (userData) {
      setUser(JSON.parse(userData));
      // console.log("userData" + userData);
    }
  }, []);
  //todo----------------
  // Load cart data from localStorage when component mounts
  // useEffect(() => {
  //   localStorage.setItem("Cart", JSON.stringify(cart));
  //   // console.log("Cart" + JSON.stringify(cart));
  // }, [cart]);

  //todo----------------
  //todo----------------
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let ans = 0;
    cart.forEach((item) => (ans += item.quantity * item.discounted_price));
    setsub_amount(ans);
  }, [cart]);
  const total_amount = deliveryFee + sub_amount;
  const total_amountIraq = deliveryFeeIraq + sub_amount;

  //todo ----------------------------------
  const handleRemove = (id) => {
    const updatedCart = cart.filter((item) => item.id !== id);
    setCart(updatedCart);
  };
  // eslint-disable-next-line no-unused-vars
  const { TextArea } = Input;

  const confirm = async () => {
    const cook = await Cookies.get("login");
    const orderJson = {
      total_delivery: deliveryFee,
      sub_amount: sub_amount,
      total_amount: total_amount,
      note: note,
    };

    if (user?.name !== null && user?.name !== undefined) {
      orderJson.username = user.name;
    }

    if (user?.number !== null && user?.number !== undefined) {
      orderJson.phone_number = user.number;
    }

    if (user?.email !== null && user?.email !== undefined) {
      orderJson.email = user.email;
    }

    if (user?.address !== null && user?.address !== undefined) {
      orderJson.address = user.address;
    }

    if (user?.id !== null && user?.id !== undefined) {
      orderJson.user_id = user.id.toString();
    }

    orderJson.products = JSON.stringify(
      cart.map((item) => ({
        product_id: item.id,
        quantity: item.quantity,
      }))
    );

    // eslint-disable-next-line eqeqeq
    if (cook == "true") {
      // eslint-disable-next-line no-unused-vars

      const URL = "https://bardawamplus.com/api/orders";

      // console.log("Order JSON:", JSON.stringify(orderJson));
      await fetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(orderJson),
      })
        .then((response) => response.json())
        .then(() => {
          // console.log("Order Details:", data);
          // message.success("Your order has been submitted successfully");
          setSuccessModalVisible(true);
        })
        .catch(() => {
          // console.error("Error submitting order:", error);
          // message.error("Failed to submit your order");
        });
    } else {
      window.location.href =
        "/Register?orderJson=" + encodeURIComponent(JSON.stringify(orderJson));
      // window.location.href = "/user?" + JSON.stringify(orderJson);
    }
  };

  const layout = {};

  const onFinish = (values) => {
    confirm(values);
  };

  // ===========================================
  if (cart.length > 0) {
    return (
      <div
        style={{
          direction: "rtl", // Set direction to right-to-left
        }}
      >
        <article>
          {cart?.map((item) => (
            <div className="cart_box" key={item.id}>
              <div className="cart_img">
                <img
                  src={`https://bardawamplus.com/api/storage/${item.image_url}`}
                />
              </div>
              <div>
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {getTranslatedName(item.name)}
                </p>
              </div>
              <div>
                <div>
                  <Button
                    style={{
                      fontWeight: "bold",
                    }}
                    onClick={() => handleChange(item, +1)}
                  >
                    +
                  </Button>
                  <Button
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {item.quantity}
                  </Button>
                  <Button
                    onClick={() => handleChange(item, -1)}
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    -
                  </Button>
                </div>
              </div>
              <div>
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  IQD {item.discounted_price}
                </span>
              </div>
              <div>
                <Button
                  style={{
                    background: "red",
                    color: "white",

                    fontWeight: "bold",
                  }}
                  onClick={() => handleRemove(item.id)}
                >
                  {t("remove")}
                </Button>
              </div>
            </div>
          ))}
          <div className="total">
            <span>{t("Cart")}</span>
            <span>IQD {sub_amount}</span>
          </div>

          <div className="total">
            <span>{t("Cart in kurdtsan")}</span>
            <span>IQD {total_amount}</span>
          </div>
          <div className="total">
            <span>{t("Cart out kurdtsan")}</span>
            <span>IQD {total_amountIraq}</span>
          </div>
          {/* //todo ____ this is form ______*/}
          <div>
            <Form
              {...layout}
              onFinish={onFinish}
              initialValues={{ note: "" }}
              className="cart-form" // Apply CSS class to the form
            >
              <Form.Item
                name="note"
                label={t("Note")}
                rules={[{ required: true, message: t("Note out said") }]}
                className="cart-form-label"
              >
                <Input.TextArea
                  rows={3}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder={t("Note in said")}
                  className="cart-form-input" // Apply CSS class to the input field
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="cart-form-submit-button"
                >
                  {t("order")}
                </Button>
              </Form.Item>
            </Form>
          </div>

          <Modal
            // title="Order Success"
            open={successModalVisible} // Change 'visible' to 'open'
            onCancel={() => setSuccessModalVisible(false)}
            footer={[
              <Button
                key="ok"
                type="primary"
                onClick={() => {
                  window.location.reload();
                  window.location.href = "/profile";
                  setSuccessModalVisible(false);
                }}
              >
                OK
              </Button>,
            ]}
          >
            <p>{t("Your order has been submitted successfully!")}</p>
          </Modal>
        </article>
      </div>
    );
  } else {
    return (
      <div>
        <h1 className="empty-cart-message">{t("CartShop is empty")}</h1>
        {/* <h1 className="empty-cart-message1">
          {t("bazar")} <Link to="/">{t("MYshoping")}</Link>
        </h1> */}
      </div>
    );
  }
};
export default CartShop;
