/* eslint-disable jsx-a11y/anchor-has-content */
import "./Footer.css"; // Import your CSS file
import Facebook from "./image/facebook (1).png";
import instagram from "./image/instagram.png";
import tiktok from "./image/tik-tok.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="x">
      <footer>
        <div className="footer-containertop">
          {t("contact us with social medal")}
        </div>
        <div className="all">
          <div className="footer-container">
            <div className="footer-column">
              <h4>
                <a
                  href="https://www.facebook.com/Brdawam?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="facebook" className="image_footer" />
                </a>
              </h4>
              <p>
                <a
                  className="text_under_icon"
                  href="https://www.facebook.com/Brdawam?mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bardawam بەردەوام ـ
                </a>
              </p>
            </div>

            <div className="footer-column">
              <h4>
                <a
                  s
                  href="https://www.instagram.com/bardawam_plus?igsh=OGQ5ZDc2ODk2ZA"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={instagram}
                    alt="facebook"
                    className="image_footer"
                  />
                </a>
              </h4>
              <p>
                <a
                  className="text_under_icon"
                  s
                  href="https://www.instagram.com/bardawam_plus?igsh=OGQ5ZDc2ODk2ZA"
                  target="_blank"
                  rel="noreferrer"
                >
                  bardawam_plus
                </a>
              </p>
            </div>

            <div className="footer-column">
              <h4>
                <a
                  href="https://www.tiktok.com/@bardawamplus?_t=8jIwqSJNr9B&_r=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={tiktok} alt="facebook" className="image_footer" />
                </a>
              </h4>
              <p>
                <a
                  className="text_under_icon"
                  href="https://www.tiktok.com/@bardawamplus?_t=8jIwqSJNr9B&_r=1"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bardawam Plus
                </a>
              </p>
            </div>

            <div className="footer-column">
              <h4>
                <a
                  href="https://www.facebook.com/profile.php?id=100077434846075&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="facebook" className="image_footer" />
                </a>
              </h4>
              <p>
                <a
                  className="text_under_icon"
                  href="https://www.facebook.com/profile.php?id=100077434846075&mibextid=ZbWKwL"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bardawam Plus
                </a>
              </p>
            </div>
          </div>
          <hr className="footer-hr" />
          <div className="footer-bottom">
            <span>&copy; 2024 Bardawam Plus</span>
            {/* <a href="/privacy-policy">Privacy Policy</a> */}
            <p>
              <a
                href="https://www.facebook.com/TechTopia1998"
                target="_blank"
                style={{
                  color: "#05eeff",
                }}
                rel="noreferrer"
              >
                TechTopia
              </a>
              {t("This website created by TechTop")}
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
