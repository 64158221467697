import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./FrontEnd/page/Home.js";
import Navbar from "./FrontEnd/NavBar/Navbar.js";
import Gift from "./FrontEnd/page/Gift.js";
// import SuperApp from "./FrontEnd/SuperApp/SuperApp.js";
import i18n from "./i18n";
import Footer from "./FrontEnd/Components/Footer.js";
import Baran from "./FrontEnd/page/Baran.js";
import AboutUs from "./FrontEnd/page/AboutUs.js";
import Profile from "./FrontEnd/page/Profile.js";
// import PageSlider from "./FrontEnd/Slider/Slider.js";
import HomeShop from "./FrontEnd/Navbarshoping/HomeShoping.js";
import CartShop from "./FrontEnd/Navbarshoping/CartSphop.js";

import { message } from "antd";
import { useTranslation } from "react-i18next";
import Login from "./FrontEnd/page/Login.js";
import Register from "./FrontEnd/page/Register.js";
import Network from "./FrontEnd/page/Network.js";
function App() {
  useEffect(() => {
    i18n.changeLanguage("ku");
  }, []);
  const { t } = useTranslation();
  const [show, setShow] = useState(true);
  const [cart, setCart] = useState([]);
  // Define handleChange function

  const handleClick = (item) => {
    let isPresent = false;
    cart.forEach((pro) => {
      if (item.id === pro.id) {
        isPresent = true;
      }
    });
    if (!isPresent) {
      setCart([...cart, item]);
      // console.log(item);
      message.success(t("cart"));
    }
  };

  //todo handleChange
  const handleChange = (item, d) => {
    let ind = -1;
    cart.forEach((data, index) => {
      if (data.id === item.id) ind = index;
    });
    const tempArr = cart;
    tempArr[ind].quantity += d;

    if (tempArr[ind].quantity === 0) tempArr[ind].quantity = 1;
    setCart([...tempArr]);
  };
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home size={cart.length} setShow={setShow} />}>
          {show ? (
            <Route
              path="/"
              element={<HomeShop handleClick={handleClick} setShow={setShow} />}
            />
          ) : (
            <Route
              path="Cart"
              element={
                <CartShop
                  cart={cart}
                  setCart={setCart}
                  handleChange={handleChange}
                />
              }
            />
          )}
        </Route>
        {/* <Route path="/" element={<HomeShop handleClick={handleClick} />} />
          <Route
            path="/Cart"
            element={
              <CartShop
                cart={cart}
                setCart={setCart}
                handleChange={handleChange}
              />
            }
          />
        </Route> */}
        <Route path="Register" element={<Register />} />
        <Route path="Login" element={<Login />} />
        <Route path="Profile" element={<Profile />} />
        <Route path="/Profile/Network/:id" element={<Network />} />
        <Route path="Gift" element={<Gift />} />
        <Route path="AboutUs" element={<AboutUs />} />
        <Route path="*" element={<Baran />} />
      </Routes>
      <Footer />
    </div>
  );
}
export default App;
